'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

require('react');
var _interface = require('./interface.js');

var GetDevice = function () {
  var width = window.innerWidth,
      height = window.innerHeight,
      portrait = height >= width;

  if (width <= 320) {
    return _interface.Vdevice.MINI_MOBILE;
  } else if (width >= 320 && width <= 480) {
    return _interface.Vdevice.MOBILE_PORTRAIT;
  } else if (width >= 481 && width <= 767) {
    return _interface.Vdevice.MOBILE_LANDSCAPE;
  } else if (width >= 768 && width <= 1024) {
    return portrait ? _interface.Vdevice.TAB_PORTRAIT : _interface.Vdevice.TAB_LANDSCAPE;
  } else if (width >= 1025 && width <= 1280) {
    return _interface.Vdevice.LAPTOP;
  } else if (width >= 1281) {
    return _interface.Vdevice.DESKTOP;
  }

  return _interface.Vdevice.DESKTOP;
};
var IsMobile = function () {
  var device = GetDevice();
  return device == _interface.Vdevice.MINI_MOBILE || device == _interface.Vdevice.MOBILE_PORTRAIT || device == _interface.Vdevice.MOBILE_LANDSCAPE; // window.innerWidth <= 750
};
var IsVIOCO = function () {
  var device = GetDevice();
  return device == _interface.Vdevice.MINI_MOBILE || device == _interface.Vdevice.MOBILE_PORTRAIT || device == _interface.Vdevice.MOBILE_LANDSCAPE || device == _interface.Vdevice.TAB_PORTRAIT || device == _interface.Vdevice.TAB_LANDSCAPE;
};

exports.GetDevice = GetDevice;
exports.IsMobile = IsMobile;
exports.IsVIOCO = IsVIOCO;
