'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.Vdevice = void 0;

(function (Vdevice) {
  // WATCH = 'watch',
  Vdevice["MINI_MOBILE"] = "mob-sm";
  Vdevice["MOBILE_PORTRAIT"] = "mob";
  Vdevice["MOBILE_LANDSCAPE"] = "mob-l";
  Vdevice["TAB_PORTRAIT"] = "tab";
  Vdevice["TAB_LANDSCAPE"] = "tab-l";
  Vdevice["LAPTOP"] = "lap";
  Vdevice["DESKTOP"] = "desk"; // TV = 'tv'
})(exports.Vdevice || (exports.Vdevice = {}));
