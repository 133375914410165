import React, { lazy } from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

const Layout = lazy(
  () => import(/* webpackChunkName: "Settings" */ '@pages/settings')
)
const Home = lazy(
  () =>
    import(/* webpackChunkName: "Settings.Home" */ '@pages/settings/pages/home')
)
const Profile = lazy(
  () =>
    import(
      /* webpackChunkName: "Settings.profile" */ '@pages/settings/pages/profile'
    )
)
const routes: RouteObject[] = [
  {
    path: '/settings',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to={'/settings/home'} />
      },
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'profile',
        element: <Profile />
      }
    ]
  }
]
export default routes
